<template>
  <div>
    <v-dialog :value="status" persistent max-width="650px" transition="dialog-transition">
      <v-card>
        <v-card-title>{{title}}</v-card-title>
        <v-card-text>
          <p class="darkGrey--text">{{description}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="confrimDelete" elevation="0" color="error" class="text-capitalize">Confirm</v-btn>
          <v-btn @click="cancel" color="darkGrey" text class="text-capitalize">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean
    },
    callback: {
      type: Function
    },
    title: {
      type: String,
      default: 'Are you sure you want to delete?'
    },
    description: {
      type: String,
      default: 'This will permanently delete the item that you have selected. Are you sure you want to continue?'
    }
  },
  methods: {
    async confrimDelete() {
      this.spinner(true)
      let response
      try {
        response = await this.callback()
      } catch (error) {
        this.spinner(false)
        console.log('error', error)
        return this.$emit('error', error)
      }
      this.$emit('success', { response })
      this.spinner(false)
    },
    cancel() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
